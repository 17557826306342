import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
// Customizable Area End

import StudentPageController, {
  Props,
  commonWebStyle
} from "./StudentPageController";

export default class StudyAbroadDreams extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: "100%", maxWidth: "1248px", margin: "0 auto" }}>
        <Grid container spacing={2} style={{ marginBottom: "150px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            style={webStyle.studyAbroadDreamLeftSection}
          >
            <img
              style={webStyle.studyAbroadDreamImage}
              src={require("../../assets/study-abroad.svg")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} />
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={webStyle.studyAbroadDreamRightSection}
          >
            <h1 style={webStyle.studyAbroadDreamHeading}>
              Achieve Your Study <br />
              <span style={webStyle.textHighlight}>Abroad Dreams</span>{" "}
            </h1>
            <p style={webStyle.studyAbroadDreamText}>
              Ready to launch your study abroad journey? It can be intimidating,
              but we’re here for you! Follow in the steps of 600,000+ other
              students and trust our experts to guide your way.
            </p>
            <button style={webStyle.studyAbroadDreamBtn}>
              Get Personalized Study Abroad Options{" "}
              <img
                style={{ marginLeft: "8px" }}
                src={require("../../assets/arrow.svg")}
              />
            </button>
            <button style={webStyle.studyAbroadDreamRegisterBtn}>
              Register for free{" "}
              <img
                style={{
                  marginLeft: "8px"
                }}
                src={require("../../assets/arrow-blue.svg")}
              />
            </button>
          </Grid>
        </Grid>
        <div style={{ position: "relative", right: "-95px" }}>
          <div style={commonWebStyle.rightDoottedBackground}>
            <img
              style={{ width: "250px" }}
              src={require("../../assets/dot-bg.svg")}
            />
          </div>
        </div>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textHighlight: {
    color: "#2F57A5"
  },
  studyAbroadDreamRightSection: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "150px"
  },
  studyAbroadDreamLeftSection: {
    width: "100%"
  },
  studyAbroadDreamHeading: {
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "left" as const,
    color: "#060A14",
    marginBottom: "32px"
  },
  studyAbroadDreamText: {
    width: "421px",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "200%",
    letterSpacing: "0.043px",
    color: "#64748B"
  },
  studyAbroadDreamBtn: {
    width: "max-content",
    height: "56px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    color: "white",
    fontFamily: "ZonaPro",
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    marginTop: "30px",
    padding: "8px 32px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  studyAbroadDreamRegisterBtn: {
    width: "447px",
    height: "44px",
    background: "#FFFFFF",
    border: "1px solid #E2E8F0",
    color: "#2F57A5",
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    marginTop: "16px",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center"
  },
  studyAbroadDreamImage: {
    position: "relative" as const,
    left: "-150px"
  }
};
// Customizable Area End
