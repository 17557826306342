// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Rupayintegration from "../../blocks/rupayintegration/src/Rupayintegration";
import Businesschat from "../../blocks/businesschat/src/Businesschat";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Whatsappintegration20 from "../../blocks/whatsappintegration20/src/Whatsappintegration20";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Interactivecalculator2 from "../../blocks/interactivecalculator2/src/Interactivecalculator2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Storelocator2 from "../../blocks/storelocator2/src/Storelocator2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Manageblogcomments from "../../blocks/manageblogcomments/src/Manageblogcomments";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Blogpostsmanagement from "../../blocks/blogpostsmanagement/src/Blogpostsmanagement";
import Testimonials2 from "../../blocks/testimonials2/src/Testimonials2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Paypalintegration2 from "../../blocks/paypalintegration2/src/Paypalintegration2";
import Leadmanagement2 from "../../blocks/leadmanagement2/src/Leadmanagement2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Chatbot from "../../blocks/chatbot/src/Chatbot";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Ocropticalcharacterrecognition from "../../blocks/ocropticalcharacterrecognition/src/Ocropticalcharacterrecognition";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import AboutUsPage from "../../blocks/landingpage/src/AboutUsPage.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customadvertisements from "../../blocks/customadvertisements/src/Customadvertisements";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Multipageforms2 from "../../blocks/multipageforms2/src/Multipageforms2";
import AppHeader from "../../components/src/AppHeader.web";
import AppFooter from "../../components/src/AppFooter.web";
import StudentPage from "../../blocks/catalogue/src/studentpage/StudentPage.web"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const routeMap = {
Rupayintegration:{
 component:Rupayintegration,
path:"/Rupayintegration"},
Businesschat:{
 component:Businesschat,
path:"/Businesschat"},
Productdescription3:{
 component:Productdescription3,
path:"/Productdescription3"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
Whatsappintegration20:{
 component:Whatsappintegration20,
path:"/Whatsappintegration20"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Interactivecalculator2:{
 component:Interactivecalculator2,
path:"/Interactivecalculator2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Storelocator2:{
 component:Storelocator2,
path:"/Storelocator2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Manageblogcomments:{
 component:Manageblogcomments,
path:"/Manageblogcomments"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Blogpostsmanagement:{
 component:Blogpostsmanagement,
path:"/Blogpostsmanagement"},
Testimonials2:{
 component:Testimonials2,
path:"/Testimonials2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Paypalintegration2:{
 component:Paypalintegration2,
path:"/Paypalintegration2"},
Leadmanagement2:{
 component:Leadmanagement2,
path:"/Leadmanagement2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Chatbot:{
 component:Chatbot,
path:"/Chatbot"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
// GroupVideoCall:{
//  component:GroupVideoCall,
// path:"/GroupVideoCall"},
Ocropticalcharacterrecognition:{
 component:Ocropticalcharacterrecognition,
path:"/Ocropticalcharacterrecognition"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
AboutUsPage:{
 component:AboutUsPage,
path:"/AboutUsPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customadvertisements:{
 component:Customadvertisements,
path:"/Customadvertisements"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Multipageforms2:{
 component:Multipageforms2,
path:"/Multipageforms2"},
StudentPage:{
  component: StudentPage,
path:"/Students"},

  Home: {
// component:VisualAnalytics,
  component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <div>
        <div className="scrollPage">
         <AppHeader/>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <AppFooter/>
        <Chatbot/>
      </div>
      </div>
    );
  }
}

export default App;
