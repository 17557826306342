import React from "react";

import {
    Container,
    Box,
    Grid
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    whileRIghtArrow,
    BlueRightArrow,
    playIcon2,
    bgDotsSmall,
} from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class VideoContent extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const videoUrl = "https://player.vimeo.com/external/600284816.sd.mp4?s=6dd6e76611df093168e89c7a222ce3ae7ab7756b&profile_id=164&oauth2_token_id=57447761"
        return (
            // Customizable Area Start
            <>
                <div className="VideoContentSection sectionpadding">
                    <div className="sectionBackgroundLeft" >
                        <Container style={{ maxWidth: "1500px" }}>
                            <Box className="videoContentBox">
                                <div className="textImageTop sectionHeading videoContentHeader">
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Box className="MoibleCoulmn headSubMargin">
                                                <h1 className="sectionHeading blueHeading">
                                                    We’re Passionate About Making Education
                                                </h1>
                                                <h1 className="sectionHeading blackHeading">
                                                    Accessible for Everyone
                                                </h1>
                                            </Box>
                                            <Box>
                                                <p className={"subHeading"}>
                                                    We believe that education should be accessible to all.
                                                </p>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="videoContentBottom sectionPadding">
                                    <div className="twoBoxWrapper pTb100">
                                        <div className="contentBox twoBoxColumn twoBoxColumntext">
                                            <h1>For Students</h1>
                                            <p className="videoContentWidth">Storyboard believes that education should be accessible to all.
                                                We delivered 1,000 backpacks and university supplies to empower
                                                children in India to dream big and work hard to achieve their dreams.
                                            </p>
                                            <div className="Button">
                                                <Link to="" className="blueBtn blueBtnRIghtArrow" href="/">Discover more<img src={BlueRightArrow} alt="" /></Link>
                                            </div>
                                        </div>
                                        <div className="VideoBox twoBoxColumn  ">
                                            {this.state.vidFirstPlaying ?
                                                <video
                                                    autoPlay
                                                    loop
                                                    
                                                    controls
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video> : null}
                                            {!this.state.vidFirstPlaying ?
                                                <video
                                                    loop
                                                    
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video> : null}
                                            {!this.state.vidFirstPlaying ?
                                                <img src={playIcon2} alt="" onClick={() => this.handlePassionateSectionVid('video1')} data-test-id="handle-icon" /> : null}
                                        </div>
                                        <div style={{position:"relative"}}>
                                            <div className="bgDotsSmallimg_right"> <img src={bgDotsSmall} alt="" /></div>
                                        </div>
                                    </div>
                                    <div className="twoBoxWrapper">
                                        <div className="VideoBox twoBoxColumn">
                                        <div style={{position:"relative"}}>
                                            <div className="bgDotsSmallimg_left"> <img src={bgDotsSmall} alt="" /></div>
                                        </div>
                                            {this.state.vidSecondPlaying ?
                                                <video
                                                    autoPlay
                                                    loop
                                                    
                                                    controls
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video>
                                                : null}

                                            {!this.state.vidSecondPlaying ?
                                                <video
                                                    loop
                                                    
                                                >
                                                    <source src={videoUrl} type="video/mp4" />
                                                </video>
                                                :
                                                null}
                                            {!this.state.vidSecondPlaying ?
                                                <img src={playIcon2} alt="" onClick={() => this.handlePassionateSectionVid('video2')} />
                                                : null}
                                        </div>
                                        <div className="contentBox twoBoxColumn twoBoxColumntext">
                                            <h1>Scholarship</h1>
                                            <p className="videoContentWidth">The STEM for Change Scholarship seeks to drive
                                                diversity and inclusion by empowering women worldwide to pursue an education in STEM.
                                                See how we surprised the 2021 recipients.
                                            </p>
                                            <div className="Button">
                                                <Link to="" className="blueBtn blueBtnRIghtArrow" href="/">Discover more<img src={BlueRightArrow} alt="" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="VideoContentlink mobileDNone">
                                    <Link
                                        to=""
                                        className="blueBtn"
                                        href="/"
                                        style={{ margin: "0 auto" }}
                                    >Our Story
                                        <img src={whileRIghtArrow} alt="" />
                                    </Link>
                                </div>
                            </Box>
                        </Container>
                        <div style={{ position: "relative" }}>
                            <div className="side-BackGround-Effect-right"></div>
                        </div>
                    </div>
                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
