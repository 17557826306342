import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

import StudentPageController, {
  Props,
  commonWebStyle
} from "./StudentPageController";

export default class StudyAbroadSuccess extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  studyAbroadSteps = [
    {
      step: "01",
      title: "Students check their eligibility",
      icon: "student.png"
    },
    {
      step: "02",
      title: "Submit your application",
      icon: "apply.png"
    },
    {
      step: "03",
      title: "Get a letter of Acceptance",
      icon: "letter.png"
    },
    {
      step: "04",
      title: "Apply for your Visa",
      icon: "visa.png"
    },
    {
      step: "05",
      title: "Start your journey",
      icon: "map.png"
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Box style={webStyle.mainBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h1 style={webStyle.studyAbroadSuccessHeading}>
                Your <span style={webStyle.textHighlight}>Study Abroad </span>
                Success <br />
                Comes First
              </h1>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={webStyle.studyAbroadLeftSection}
            >
              <p style={webStyle.studyAbroadSuccessText}>We’ve Helped</p>
              <h1 style={webStyle.studyAbroadSuccessNumber}>600,000+</h1>
              <p style={webStyle.studyAbroadSuccessSubtext}>
                Students Find Study Abroad Success
              </p>
              <p style={webStyle.studyAbroadSuccessDetailText}>
                Wondering how to apply to universities and colleges abroad? You
                make it happen, but StoryBoard makes it easy. Choose from
                140,000+ programs at 1,750+ partner institutions around the
                world!{" "}
              </p>
              <p style={webStyle.studyAbroadSuccessBottomText}>
                Let our team of experts guide you through your study abroad
                journey in five simple steps:
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={webStyle.studyAbroadRightSection}
            >
              <img
                style={webStyle.studyAbroadRightSectionImage}
                src={require("../../assets/study-abroad-success.svg")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{ marginTop: "78px", marginLeft: "9%", maxHeight: "500px" }}
        >
          <Slider {...this.settings}>
            {this.studyAbroadSteps.map((element, index) => (
              <Box key={index}>
                <Box style={webStyle.stepBlock}>
                  <Box style={webStyle.stepIconNumber}>
                    <Box>
                      <img
                        style={webStyle.stepIcon}
                        src={require("../../assets/" + element.icon)}
                      />
                    </Box>
                    <Box>
                      <p style={webStyle.stepNumber}>{element.step}</p>
                    </Box>
                  </Box>
                  <p style={webStyle.stepTitle}>{element.title}</p>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box style={commonWebStyle.leftBlueBackground} />
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainBox: {
    width: "100%",
    maxWidth: "1400px",
    margin: "0 auto"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  studyAbroadSuccessHeading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "100px"
  },
  studyAbroadSuccessText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "130%",
    letterSpacing: "6.5px",
    textAlign: "left" as const,
    textTransform: "uppercase" as const,
    color: "#64748B"
  },
  studyAbroadSuccessNumber: {
    fontFamily: "Gilroy-Heavy",
    fontWeight: 800,
    fontSize: "136.82px",
    lineHeight: "219.256px",
    textAlign: "left" as const,
    letterSpacing: "5.473px",
    color: "transparent",
    WebkitTextStrokeWidth: "5px",
    WebkitTextStrokeColor: "#2F57A5"
  },
  studyAbroadSuccessSubtext: {
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "30px",
    color: "#060A14"
  },
  studyAbroadSuccessDetailText: {
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "30px",
    color: "#060A14",
    marginTop: "66.28px"
  },
  studyAbroadSuccessBottomText: {
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "30px",
    color: "#060A14",
    marginTop: "12px"
  },
  studyAbroadLeftSection: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    paddingRight: "200px"
  },
  studyAbroadRightSection: {
    marginTop: "-150px"
  },
  studyAbroadRightSectionImage: {
    paddingLeft: "0px"
  },
  stepBlock: {
    height: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #EBF0F9",
    borderRadius: "8px",
    margin: "16px",
    padding: "60px 40px"
  },
  stepBlockText: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "120%",
    color: "#060A14"
  },
  stepIconNumber: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    marginBottom: "32px"
  },
  stepIcon: {
    width: "68px"
  },
  stepNumber: {
    fontFamily: "Gilroy-Heavy",
    fontWeight: 800,
    fontSize: "66px",
    lineHeight: "120%",
    color: "transparent",
    WebkitTextStrokeWidth: "2px",
    WebkitTextStrokeColor: "#CBD5E1"
  },
  stepTitle: {
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "120%",
    color: "#060A14"
  }
};
// Customizable Area End
