import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
    AboutUsSteeperImg
} from "./assets";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class AboutUsSteeper extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  steps = [
    {
        id:1,
        text: "Helping Students Achieve Success",
        isActive:false
    },
    {
        id:2,
        text: "Caring About Each Other",
        isActive:false

    },
    {
        id:3,
        text: "Delivering A+ Customer Experience"
    },
    {
        id:4,
        text: "Taking Ownership"
    },
    {
        id:5,
        text: "Innovating and Improving"
    },
    {
        id:6,
        text: "Making Work Fun"
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className="sectionStudentHelp sectionTextImage sectionPadding">
          <Container style={{ maxWidth: "1500px" }}>
            <div className="textImageTop sectionHeading">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box style={{ marginBottom: "32px" }}>
                    <h1 className="sectionHeading blackHeading">
                    At StoryBoard We're
                    </h1>
                    <h1 className="sectionHeading blueHeading">
                    <span style={{color:"#060A14"}} > Committed to</span> Our Values
                    </h1>
                    
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                      We believe that education should be accessible to all.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="textImageBottom">
              <div className="textImageBottomInner">
                <Container style={{
                    maxWidth: "inherit",
                    paddingLeft: "0px",
                    paddingRight:"0"
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <div style={{maxWidth:"320px",height:"100%"}}>
                            {this.steps.map((step,index)=>(
                                <div className="TextMain" style={{background: this.state.activeIndex===index? '#EBF0F9':"#fff"}}>
                                    <div style={{display:"flex",margin:"15px"}}> 
                                        <span className="sequenceNumber" style={{
                                            background: this.state.activeIndex===index?'#2F57A5':"#EBF0F9",
                                            color: this.state.activeIndex===index?'#ffffff':"#334155",
                                        }}>{step.id}</span> 
                                        <span style={{color:"#334155"}} >{step.text}</span> 
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{marginBottom: "80px"}}>
                        <div className="textImageRight">
                            <img src={AboutUsSteeperImg} alt="" />
                        </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </Container>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
