import React from "react";
// Customizable Area Start
import { Box, Grid, Button } from "@material-ui/core";
// Customizable Area End

import StudentPageController, { Props } from "./StudentPageController";

export default class TopSection extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.topSectionContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h1 style={webStyle.topSectionHeading}>
                Search in Seconds.{" "}
                <span style={webStyle.textHighlight}>Find Your</span> <br />
                <span style={webStyle.textHighlight}>Dream Program</span> in
                Minutes.
              </h1>
              <p style={webStyle.topSectionSubText}>
                Take our short quiz to be matched with study abroad <br />{" "}
                programs tailored to you
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button style={webStyle.studyAbroadOptionsBtn}>
                Get Personalized Study Abroad Options{" "}
                <span>
                  {" "}
                  <img
                    style={{ marginLeft: "8px" }}
                    src={require("../../assets/arrow.svg")}
                  />
                </span>
              </button>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Box style={webStyle.elipseImage}>
                <video
                  style={
                    this.state.isVideoVisible
                      ? webStyle.fullElipseVideo
                      : webStyle.elipseVideo
                  }
                  src="https://www.shutterstock.com/shutterstock/videos/1083186550/preview/stock-footage-a-group-of-asian-high-school-students-uses-laptops-to-program-a-renewable-energy-project-for-a.webm"
                  autoPlay
                  loop
                  muted
                />
              </Box>
            </Grid>

            <Grid item xs={12} style={webStyle.searchFormCard}>
              <Box style={{ marginBottom: "24px" }}>
                <p style={webStyle.searchFormLabel}>
                  Use our AI-powered platform to find your perfect program in
                  seconds
                </p>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <Box width={1}>
                    <input
                      style={webStyle.inputField}
                      placeholder="What would you like to study?"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5}>
                  <Box width={1}>
                    <input
                      style={webStyle.inputField}
                      placeholder="Where? e.g school name or location"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Box width={1}>
                    <Button style={webStyle.searchBtn}>
                      Search{" "}
                      <img
                        style={{ marginLeft: "8px" }}
                        src={require("../../assets/arrow.svg")}
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={webStyle.statGrid}>
                <Box style={webStyle.statText}>
                  <p style={webStyle.statTextNumber}>600,000 +</p>
                  <p>Students helped</p>
                </Box>
                <Box style={webStyle.statText}>
                  <p style={webStyle.statTextNumber}>150,000 +</p>
                  <p>Programs</p>
                </Box>
                <Box style={webStyle.statText}>
                  <p style={webStyle.statTextNumber}>20,000 +</p>
                  <p>Partners</p>
                </Box>
                <Box style={webStyle.statText}>
                  <p style={webStyle.statTextNumber}>1750 +</p>
                  <p>Universities</p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  topSectionContainer: {
    width: "100%",
    maxWidth: "1280px",
    margin: "0 auto"
  },
  topSectionHeading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "66px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "32px",
    marginTop: "165px"
  },
  topSectionSubText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "38px",
    textAlign: "center" as const,
    letterSpacing: "0.25px",
    color: "#334155"
  },
  textHighlight: {
    color: "#2F57A5"
  },
  studyAbroadOptionsBtn: {
    padding: "15px 41px 15px 41px",
    width: "max-content",
    height: "56px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "ZonaPro",
    fontWeight: 700 as const,
    fontSize: "17px",
    lineHeight: "24px",
    textAlign: "center" as const,
    textTransform: "uppercase" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "41px",
    cursor: "pointer"
  },
  elipseImage: {
    width: "1280",
    height: "640px",
    borderTopLeftRadius: "800px",
    borderTopRightRadius: "800px",
    borderBottom: "0",
    marginTop: "50px",
    marginBottom: "80px"
  },
  elipseVideo: {
    width: "100%",
    borderTopLeftRadius: "800px",
    borderTopRightRadius: "800px"
  },
  fullElipseVideo: {
    width: "100vw",
    height: "700px",
    objectFit: "cover" as const,
    position: "absolute" as const,
    left: "0px",
    transition: "all 0.5s ease-in-out 0s"
  },
  searchFormCard: {
    padding: "40px 60px",
    width: "1280px",
    height: "100%",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "24px",
    marginBottom: "122px",
    marginTop: "100px"
  },
  searchFormLabel: {
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "38px",
    letterSpacing: "0.055px"
  },
  searchFormFields: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "8px"
  },
  inputField: {
    padding: "10px 8px 10px 24px",
    gap: "8px",
    width: "100%",
    height: "56px",
    background: "#FFFFFF",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.043px",
    color: "#64748B"
  },
  searchBtn: {
    fontFamily: "ZonaPro",
    width: "100%",
    height: "56px",
    background: "#2F57A5",
    border: "1px solid #2F57A5",
    color: "white",
    fontSize: "17px",
    textTransform: "uppercase" as const,
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    cursor: "pointer"
  },
  statGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textAlign: "center",
    gap: "8px",
    marginTop: "40px"
  },
  statText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "30px",
    textTransform: "uppercase" as const
  },
  statTextNumber: {
    marginBottom: "12px"
  }
};
// Customizable Area End
