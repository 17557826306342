import React from "react";

import {
  Container,
  Box,
  Grid
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  GroupDisucssionImg,
  findIcon,
  Group,
  scholarship,
  GroupDiscussion2,
  Knowledge,
  dataDriven,
  Computer,
  bgDotsLeft
} from "./assets";
import './landingpage.css'
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export default class ContentImg extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className="sectionStudentHelp sectionTextImage sectionPadding">
          <Container style={{ maxWidth: "1500px" }}>
            <div className="textImageTop sectionHeading">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box style={{ marginBottom: "32px" }}>
                    <h1 className="sectionHeading blueHeading">
                      We Help Students Get Admitted Into the Best
                    </h1>
                    <h1 className="sectionHeading blackHeading">
                      Domestic & International Educational Institutions
                    </h1>
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                      We believe that education should be accessible to all.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="textImageBottom">
              <div className="textImageBottomInner">
                <Container maxWidth="lg">
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={6}>
                      <div className="textImageleftTop">
                        <Grid container spacing={2}>
                          <Grid item sm={12} md={12} lg={12}>
                            <Box>
                              <h1>Students</h1>
                            </Box>
                            <Box>
                              <p
                                style={{ textAlign: "start" }}
                                className={"subHeading"}
                              >
                                We believe in your dreams and work hard.
                              </p>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="textImageLeftBottom">
                        <Box className="iconContentBox">
                          <img src={findIcon} alt="" />
                          <Box>
                            <h2>Find Programs Faster</h2>
                            <p>
                              We believe in your dreams and work hard to make
                              them a reality.
                            </p>
                          </Box>
                        </Box>
                        <Box className="iconContentBox">
                          <img src={Group} alt="" />
                          <Box>
                            <h2>Helpful and Dedicated Support Team</h2>
                            <p>
                              We believe in your dreams and work hard to make
                              them a reality.
                            </p>
                          </Box>
                        </Box>
                        <Box className="iconContentBox">
                          <img src={scholarship} alt="" />
                          <Box>
                            <h2>Access to Exclusive Scholarships</h2>
                            <p>
                            We believe in your dreams and work hard to make them a reality.
                            </p>
                          </Box>
                        </Box>
                      </div>
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <div className="textImageRight" >
                        <img src={GroupDisucssionImg} alt="" />
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </Container>
        </div>
        <div className="sectionStudentHelp sectionTextImage sectionPadding" style={{paddingTop:"70px"}}>
          <div style={{position:"relative"}}>
            <div className="left_bgImg"> <img src={bgDotsLeft} alt="" /></div>
          </div>
          <Container style={{ maxWidth: "1500px" }}>
            <div className="textImageTop sectionHeading">
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                  <Box style={{ marginBottom: "32px" }}>
                    <h1 className="sectionHeading blueHeading">
                      An Easy-to-Use Platform Built to Deliver{" "}
                    </h1>
                    <h1 className="sectionHeading blackHeading">
                      Quality Applications and More
                    </h1>
                  </Box>
                  <Box>
                    <p className={"subHeading"}>
                      We believe that education should be accessible to all.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="textImageBottom">
              <div className="textImageBottomInner">
                <Container maxWidth="lg">
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={6} style={{display: "flex",flexDirection: "column",alignItems: "center"}}>
                      <div className="textImageleftTop">
                        <Grid container spacing={2}>
                          <Grid item sm={12} md={12} lg={12}>
                            <Box>
                              <h1>Partners</h1>
                            </Box>
                            <Box>
                              <p
                                style={{ textAlign: "start" }}
                                className={"subHeading"}
                              >
                                We believe in your dreams and work hard.
                              </p>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="textImageLeftBottom" style={{marginLeft:"-75px"}}>
                        <Box className="iconContentBox">
                          <img src={Computer} alt="" />
                          <Box>
                            <h2>One Easy Application Platform</h2>
                            <p>We believe in your dreams and work hard.</p>
                          </Box>
                        </Box>
                        <Box className="iconContentBox">
                          <img src={Knowledge} alt="" />
                          <Box>
                            <h2>Knowledgeable Support Team</h2>
                            <p>
                              We believe in your dreams and work hard to make
                              them a reality.
                            </p>
                          </Box>
                        </Box>
                        <Box className="iconContentBox">
                          <img src={dataDriven} alt="" />
                          <Box>
                            <h2>Data Driven Insights</h2>
                            <p>
                              We believe in your dreams and work hard to make
                              them a reality.
                            </p>
                          </Box>
                        </Box>
                      </div>
                    </Grid>
                    <Grid
                      className="txtImgReverseColumn"
                      item
                      sm={12}
                      md={6}
                      lg={6}
                    >
                      <div className="textImageRight" style={{marginLeft:"100px"}}>
                        <img src={GroupDiscussion2} alt="" />
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </Container>
          <div style={{position:"relative"}}>
            <div className="side-BackGround-Effect-right"></div>
          </div>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
