import React, { useState, useEffect } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { appLogo } from './assets';
import { Box } from '@material-ui/core'; 

const AppHeader = () => {
  const [scrollBgColor, setScrollBgColor] = useState<string>('transparent');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = document.getElementById('appHeader')?.clientHeight || 0;
      const isScrolledToTop = scrollPosition === 0;

      if (isScrolledToTop) {
        setScrollBgColor('transparent');
      } else if (scrollPosition > headerHeight) {
        setScrollBgColor('#ffffff');
      }
    };
    const headerHeight = document.getElementById('appHeader')?.clientHeight || 0;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav id="appHeader" className="appNavbar" style={{ backgroundColor: scrollBgColor }}>
        <Box className="appNavbarLeft">
          <Link to="/">
            <img className="appLogoImg" src={appLogo} alt="" />
          </Link>
        </Box>
        <Box className="appNavbarRight">
          <input type="checkbox" id="check" />
          <label htmlFor="check" className="checkBtn">
            <MenuIcon />
          </label>
          <ul className="navLinks">
            <li>
              <Link to={'/AboutUsPage'}>About us</Link>
            </li>
            <li>
              <Link to={'/Students'}>Students</Link>
            </li>
            <li>
              <Link to={''}>Partners</Link>
            </li>
            <li>
              <Link to={''}>Universities</Link>
            </li>
            <li>
              <Link to={''}>Blogs</Link>
            </li>
            <li>
              <Box className="navbarBtn">
                <Link
                  id="second-btn"
                  className="LoginBtn"
                  style={{ marginRight: '0px' }}
                  to={''}
                >
                  Login
                </Link>
                <Link
                  id="second-btn"
                  className={'signupBtn'}
                  style={{ marginLeft: '12px' }}
                  to={''}
                >
                  Signup
                </Link>
              </Box>
            </li>
          </ul>
        </Box>
      </nav>
    </>
  );
};

export default AppHeader;
