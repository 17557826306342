import React from "react";

import {
  Container,
  Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
// Customizable Area End

import LandingPageController, { Props } from "./StudentPageController";

export default class ApplyNow extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="sectionApplyBoard sectionPadding">
        <Container style={{ maxWidth: "1500px" }}>
          <div className="ApplyNowImg">
            <img
              className="ApplyNowBgImg"
              src={require("../../assets/ApplyNowImg.jpg")}
              alt="Image"
            />
            <div
              className=""
              style={{
                width: "473px",
                height: "480px",
                padding: "78px 45px",
                position: "absolute",
                top: "-25px",
                right: "8%",
                background: "#FFF",
                borderRadius: "24px"
              }}
            >
              <div
                className="ApplyNowImgOverlayInner"
                style={{ position: "relative", top: "60px" }}
              >
                <h2
                  className="ApplyNowText"
                  style={{
                    fontSize: "50px",
                    fontStyle: "normal",
                    fontWeight: 800,
                    lineHeight: "120%"
                  }}
                >
                  Ready to get started with StudyBoard?
                </h2>
                <Box className="ApplyNowLinkBox">
                  <Link to="" className="ApplyNowLink">
                    Apply Now
                  </Link>
                  <img src={require("../../assets/arrow-blue.svg")} alt="" />
                </Box>
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="bgBottomApplyNow">
              {" "}
              <img src={require("../../assets/bgBottomApplyNow.svg")} alt="" />
            </div>
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
