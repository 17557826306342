import React from "react";
// Customizable Area Start
import { Box, Grid, Tabs, Tab, Typography } from "@material-ui/core";
// Customizable Area End

import StudentPageController, {
  Props,
  commonWebStyle
} from "./StudentPageController";

// Customizable Area Start
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
// Customizable Area End

export default class ExploreUniversities extends StudentPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const index = newValue;
    this.setState({ tabIndex: index });
  };

  tabs = [
    "India",
    "Canada",
    "United States",
    "United Kingdom",
    "Australia",
    "New zealand"
  ];

  universities = [
    {
      name: "Amity University",
      location: "Noida, India",
      banner: "amity-banner.jpeg",
      logo: "amity.png",
      intro:
        "Amity University is a forward-looking, modern university with a proud tradition as a provider of high quality education and a focus on applied research."
    },
    {
      name: "Indian Institute of Science",
      location: "Bangalore, India",
      banner: "iis-banner.jpeg",
      logo: "iis.png",
      intro:
        "At IIS University, students learn in a global community of ambitious students like themselves as well as academics, professionals and artists, industry leaders and entrepreneurs."
    },
    {
      name: "Indian Institute Of Technology",
      location: "Chennai, India",
      banner: "iit-banner.jpeg",
      logo: "iit.png",
      intro:
        "Based in one of the world's most exciting cities, Chennai IIT University is home to a diverse community of inspiring and determined learners, teachers, and innovative thinkers."
    }
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: "100%", maxWidth: "1440px", margin: "0 auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1 style={webStyle.studyAbroadSuccessHeading}>
              Explore Popular{" "}
              <span style={webStyle.textHighlight}>Universities </span> <br />
              and <span style={webStyle.textHighlight}>Colleges </span>
            </h1>
          </Grid>
          <Grid item xs={12}>
            <div style={webStyle.tabsParentDiv}>
              <Tabs
                textColor="inherit"
                centered
                variant="fullWidth"
                value={this.state.tabIndex}
                onChange={this.handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#2F57A5",
                    color: "#2F57A5"
                  }
                }}
                style={{ borderBottom: "1px solid #F0EFFB", width: "950px" }}
                data-testid="universities_tab"
              >
                {this.tabs.map((title, index) => (
                  <Tab
                    key={index}
                    style={
                      this.state.tabIndex === index
                        ? webStyle.tabTitleActive
                        : webStyle.tabTitle
                    }
                    label={title}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
              {this.tabs.map((title, index) => (
                <TabPanel value={this.state.tabIndex} index={index}>
                  <Grid
                    container
                    spacing={2}
                    style={webStyle.studyAbroadDestinationContainer}
                  >
                    {this.universities.map((element, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <div
                          style={
                            this.state.universityCardHoverIndex === index
                              ? webStyle.universityCardHover
                              : webStyle.universityCard
                          }
                          onMouseEnter={() =>
                            this.handleUniversityCardMouseEnter(index)
                          }
                          onMouseLeave={() =>
                            this.handleUniversityCardMouseLeave(-1)
                          }
                        >
                          <div style={webStyle.universityCardImageDiv}>
                            <img
                              style={webStyle.universityCardImage}
                              src={require("../../assets/" + element.banner)}
                            />
                          </div>
                          <div style={webStyle.universityCardContent}>
                            <div style={webStyle.universityCardRow}>
                              <div>
                                <p style={webStyle.universityTitleText}>
                                  {element.name}
                                </p>
                                <span style={webStyle.universityTitleSubText}>
                                  {element.location}
                                </span>
                              </div>
                              <div>
                                <img
                                  style={webStyle.universityLogoImage}
                                  src={require("../../assets/" + element.logo)}
                                />
                              </div>
                            </div>
                            <div style={webStyle.universityIntro}>
                              {element.intro}
                            </div>
                            <div>
                              <button
                                style={
                                  this.state.universityCardHoverIndex === index
                                    ? webStyle.exploreProgramButtonOnHover
                                    : webStyle.exploreProgramButton
                                }
                              >
                                Explore more{" "}
                                <img
                                  style={{ marginLeft: "8px" }}
                                  src={
                                    this.state.universityCardHoverIndex ===
                                    index
                                      ? require("../../assets/arrow.svg")
                                      : require("../../assets/arrow-blue.svg")
                                  }
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}

                    <Grid item xs={12} style={webStyle.discoverButtonDiv}>
                      <a style={webStyle.discoverMoreButton}>
                        Discover More{" "}
                        <img
                          style={{ marginLeft: "8px" }}
                          src={require("../../assets/arrow-blue.svg")}
                        />
                      </a>
                    </Grid>
                  </Grid>
                </TabPanel>
              ))}
            </div>
          </Grid>
        </Grid>
        <div style={{ position: "relative", height: "150px" }}>
          <div style={commonWebStyle.leftBlueBackground} />
        </div>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  textHighlight: {
    color: "#2F57A5"
  },
  studyAbroadSuccessHeading: {
    fontWeight: 500,
    fontSize: "50px",
    lineHeight: "120%",
    textAlign: "center" as const,
    color: "#060A14",
    marginBottom: "45px"
  },
  studyAbroadDestinationContainer: {
    marginBottom: "80px",
    maxHeight: "450px"
  },
  tabTitle: {
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.043px",
    textTransform: "capitalize" as const,
    whiteSpace: "nowrap" as const,
    color: "#64748B"
  },
  tabTitleActive: {
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.043px",
    textTransform: "capitalize" as const,
    whiteSpace: "nowrap" as const,
    color: "#2F57A5"
  },
  universityCard: {
    width: "100%",
    height: "100%",
    borderRadius: "12px"
  },
  universityCardHover: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)"
  },
  universityCardImageDiv: {
    width: "100%",
    height: "250px"
  },
  universityCardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover" as const,
    borderRadius: "12px 12px 0px 0px"
  },
  universityCardContent: {
    padding: "20px",
    borderRadius: "10px",
    marginTop: "-25px",
    background: "#fff",
    position: "relative" as const,
    zIndex: 99
  },
  universityCardRow: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    marginBottom: "30px"
  },
  universityTitleText: {
    fontSize: "17px",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.043px",
    color: "#060A14"
  },
  universityTitleSubText: {
    fontSize: "13px",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "145%",
    letterSpacing: "0.033px",
    color: "#64748B"
  },
  universityIntro: {
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    lineHeight: "200%",
    letterSpacing: "0.043px",
    color: "#475569",
    height: "140px"
  },
  universityLogoImage: {
    height: "36px"
  },
  exploreProgramButtonDiv: {
    width: "100%",
    textAlign: "center" as const,
    marginTop: "30px"
  },
  exploreProgramButtonOnHover: {
    padding: "8px 24px",
    width: "100%",
    height: "48px",
    background: "#2F57A5",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    color: "#FFFFFF",
    marginTop: "24px",
    fontSize: "13px",
    fontFamily: "ZonaPro",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  exploreProgramButton: {
    padding: "8px 24px",
    width: "100%",
    height: "48px",
    background: "#E2E8F0",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    color: "#2F57A5",
    marginTop: "24px",
    fontSize: "13px",
    fontFamily: "ZonaPro",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  discoverButtonDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "150px",
    marginTop: "50px"
  },
  discoverMoreButton: {
    forntFamily: "ZonaPro",
    color: "#2F57A5",
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "24px",
    textTransform: "uppercase" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  tabsParentDiv: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap" as const
  }
};
// Customizable Area End
